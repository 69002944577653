export const IMAGE_CDN_URL = 'https://new.sportal365images.com';

export const IMAGE_RATIO_RESOLUTIONS = {
    BIG_4x3: {
        width: '3072',
        height: '2304',
    },
    MID_4x3: {
        width: '1536',
        height: '1152',
    },
    SMALL_4x3: {
        width: '960',
        height: '720',
    },
    BIG_16x9: {
        width: '3072',
        height: '1728',
    },
    MID_16x9: {
        width: '2048',
        height: '1152',
    },
    SMALL_16x9: {
        width: '1280',
        height: '720',
    },
};
