import {
    DEFAULT_IMAGE_RATIO,
    DEFAULT_IMAGE_SIZE,
    IMAGE_RATIO_RESOLUTIONS,
    ImageModel,
    ImageRatioModel,
    ImageRatios,
    ImageSizes,
} from './models/ImageModel';
import { IMAGE_CDN_URL } from '../../../constants/GlobalConstants';
import { ImageFormats } from '../../../constants/Enums';
import { VideoModel } from '../video/models/VideoModel';
import { getMainImageFromMainMedia } from '../mainMedia/MainMedia';

export const getImageByCropRatio = (
    image: ImageModel | null,
    ratio: ImageRatios = DEFAULT_IMAGE_RATIO,
    size: ImageSizes = DEFAULT_IMAGE_SIZE,
) => {
    if (image && ratio !== ImageRatios.NONE) {
        const ratioConfig = getImageRatioConfig(image, ratio);
        if (hasImageRatios(ratioConfig)) {
            return `${IMAGE_CDN_URL}/process/${image.path}?operations=crop(${ratioConfig?.top}:${ratioConfig?.left}:${ratioConfig?.bottom}:${ratioConfig?.right})&format=${ImageFormats.WEBP}`;
        } else {
            const dimensions = getImageWidthByRatioAndSize(ratio, size);
            return `${IMAGE_CDN_URL}/autocrop/${image.path}?width=${dimensions.width}&height=${dimensions.height}&format=${ImageFormats.WEBP}`;
        }
    } else if (image && image.path && ratio === ImageRatios.NONE) {
        return `${IMAGE_CDN_URL}/process/${image.path}?operations=fit(1100:608)`;
    }

    return '';
};

export const getMainMediaImageByCropRatio = (
    mainMedia: (string | ImageModel | VideoModel | null)[],
    ratio: ImageRatios = DEFAULT_IMAGE_RATIO,
    size: ImageSizes = DEFAULT_IMAGE_SIZE,
    getWebP: boolean = true,
): string => {
    const image = getMainImageFromMainMedia(mainMedia);

    let imgSrc = '';

    if (image && image.path) {
        const ratioConfig = getImageRatioConfig(image, ratio);
        if (hasImageRatios(ratioConfig)) {
            imgSrc = `${IMAGE_CDN_URL}/process/${image.path}?operations=crop(${ratioConfig?.top}:${ratioConfig?.left}:${ratioConfig?.bottom}:${ratioConfig?.right})`;
        } else {
            const dimensions = getImageWidthByRatioAndSize(ratio, size);
            imgSrc = `${IMAGE_CDN_URL}/autocrop/${image.path}?width=${dimensions.width}&height=${dimensions.height}`;
        }

        if (getWebP) {
            imgSrc += `&format=${ImageFormats.WEBP}`;
        }
    }

    return imgSrc;
};

const hasImageRatios = (ratio: ImageRatioModel | null): boolean => {
    if (ratio) {
        return ratio.top !== '0' || ratio.bottom !== '0' || ratio.left !== '0' || ratio.right !== '0';
    }

    return false;
};

const getImageRatioConfig = (image: ImageModel, ratio: ImageRatios): ImageRatioModel | null => {
    if (image.urls === null) {
        return null;
    }

    switch (ratio) {
        case ImageRatios.RATIO_1X1: {
            return image.urls?.ratio1x1;
        }
        case ImageRatios.RATIO_2X3: {
            return image.urls?.ratio2x3;
        }
        case ImageRatios.RATIO_3X2: {
            return image.urls?.ratio3x2;
        }
        case ImageRatios.RATIO_4X3: {
            return image.urls?.ratio4x3;
        }
        case ImageRatios.RATIO_3X4: {
            return image.urls?.ratio3x4;
        }
        case ImageRatios.RATIO_16X9: {
            return image.urls?.ratio16x9;
        }
        case ImageRatios.RATIO_9X16: {
            return image.urls?.ratio9x16;
        }
        default: {
            return image.urls?.ratio16x9;
        }
    }
};

const getImageWidthByRatioAndSize = (ratio: ImageRatios = ImageRatios.RATIO_4X3, size: ImageSizes = ImageSizes.MID) => {
    return IMAGE_RATIO_RESOLUTIONS[`${size}_${ratio}`];
};
