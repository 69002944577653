export enum ImageFormats {
    WEBP = 'webp',
    JPEG = 'jpeg',
    GIF = 'gif',
    PNG = 'png',
}

export enum SocialMediaIconTypes {
    FACEBOOK = 'facebook',
    MESSENGER = 'messenger',
    WHATSAPP = 'whatsapp',
    EMAIL = 'email',
    VIBER = 'viber',
    CLIPBOARD = 'clipboard',
    PINTEREST = 'pinterest',
}

export enum ContentTypes {
    ARTICLE = 'article',
    VIDEO = 'video',
    TAG = 'tag',
    AD = 'ad',
}

export enum TemplateTypes {
    LEADING_NEWS = 'leading_news',
    FOUR_ARTICLES_SINGLE_HORIZONTAL_BANNER = 'four_articles_horizontal_banner',
    FOUR_ARTICLES_HORIZONTAL_AND_RECT_BANNERS = 'four_articles_horizontal_and_rect_banners',
    FIVE_ARTICLES_VERTICAL_BANNER = 'five_articles_vertical_banner',
    FIVE_ARTICLES_NO_BANNERS = 'five_articles_no_banners',
    SPECIAL_PROJECT = 'special_project',
}

export enum Pages {
    LANDING = 'landing',
    SEARCH = 'search',
    CATEGORY = 'category',
    TAG = 'tag',
    AUTHOR = 'author',
    ARTICLE = 'article',
    VIDEOS = 'videos',
}
