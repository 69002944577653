import { ImageModel, ImageRatioModel, ImageRatios, ImageUploadedModel, ImageUrlsModel } from '../models/ImageModel';
import { normalizeOrigin } from '../../origin/normalizers/OriginNormalizer';
import { normalizeAuthors } from '../../author/normalizers/AuthorNormalizer';

export const normalizeImage = (response: any): ImageModel | null => {
    if (response) {
        return {
            id: response.id,
            entityType: response.entity_type ? response.entity_type : null,
            description: response.description ? response.description : null,
            origin: response.origin ? normalizeOrigin(response.origin) : null,
            urls: response.urls ? normalizeImageUrls(response.urls) : null,
            createdAt: response.created_at ? response.created_at : null,
            updatedAt: response.updated_at ? response.updated_at : null,
            alt: response.alt ? response.alt : null,
            caption: response.caption ? response.caption : null,
            authors: response.authors ? normalizeAuthors(response.authors) : [],
            path: response.path ? response.path : null,
            sensitiveContent: response.sensitive_content ? response.sensitive_content : null,
        };
    }

    return null;
};

const normalizeImageUrls = (imageUrlsResponse: any): ImageUrlsModel | null => {
    if (imageUrlsResponse) {
        return {
            ratio1x1: normalizeRatios(imageUrlsResponse[ImageRatios.RATIO_1X1]),
            ratio2x3: normalizeRatios(imageUrlsResponse[ImageRatios.RATIO_2X3]),
            ratio3x2: normalizeRatios(imageUrlsResponse[ImageRatios.RATIO_3X2]),
            ratio3x4: normalizeRatios(imageUrlsResponse[ImageRatios.RATIO_3X4]),
            ratio4x3: normalizeRatios(imageUrlsResponse[ImageRatios.RATIO_4X3]),
            ratio9x16: normalizeRatios(imageUrlsResponse[ImageRatios.RATIO_9X16]),
            ratio16x9: normalizeRatios(imageUrlsResponse[ImageRatios.RATIO_16X9]),
            uploaded: normalizeUploadedImages(imageUrlsResponse.uploaded),
        };
    }

    return null;
};

const normalizeUploadedImages = (imageUrlsUploaded: any): ImageUploadedModel | null => {
    if (imageUrlsUploaded) {
        return {
            original: imageUrlsUploaded.original,
            gallery: imageUrlsUploaded.gallery,
            thumbnail: imageUrlsUploaded.thumbnail,
            embed: imageUrlsUploaded.embed,
        };
    }

    return null;
};

const normalizeRatios = (imageUrlsResponseRatio: any): ImageRatioModel | null => {
    if (imageUrlsResponseRatio) {
        return {
            top: imageUrlsResponseRatio.top ? `${imageUrlsResponseRatio.top}` : '0',
            left: imageUrlsResponseRatio.left ? `${imageUrlsResponseRatio.left}` : '0',
            right: imageUrlsResponseRatio.right ? `${imageUrlsResponseRatio.right}` : '0',
            bottom: imageUrlsResponseRatio.bottom ? `${imageUrlsResponseRatio.bottom}` : '0',
        };
    }

    return null;
};
