import { VideoFile, VideoModel } from './models/VideoModel';
import { normalizeMetadata } from '../meta/normalizers/MetaDataNormalizer';
import { fetchAllVideos, fetchVideoById } from './http/VideoHttp';
import { normalizeVideo, normalizeVideos } from './normalizers/VideoNormalizer';
import { VideoListingModel } from './models/VideoListingModel';

export const extractVideoFiles = (video: VideoModel): VideoFile[] | null => {
    return video.urls?.videoFiles ? video.urls?.videoFiles : null;
};

export const getVideoById = async (videoId: string): Promise<VideoModel | null> => {
    const response = await fetchVideoById(videoId);
    return normalizeVideo(response);
};

export const getAllVideos = async (limit: string = '20', page: string = '1'): Promise<VideoListingModel | null> => {
    try {
        const videoResponse = await fetchAllVideos(limit, page);
        const videos = normalizeVideos(videoResponse.data);
        const meta = normalizeMetadata(videoResponse.meta);
        return { videos, meta };
    } catch (e) {
        console.error('Error getting all videos');
        return null;
    }
};
