import { VideoFile, VideoModel, VideoUrlsModel } from '../models/VideoModel';
import { normalizeOrigin } from '../../origin/normalizers/OriginNormalizer';
import { normalizeSeo } from '../../seo/normalizers/SeoNormalizer';
import { normalizeCategories, normalizeCategory } from '../../category/normalizers/CategoryNormalizer';
import { normalizeAuthors } from '../../author/normalizers/AuthorNormalizer';
import { normalizeMainMedia } from '../../mainMedia/normalizers/MainMediaNormalizer';

export const normalizeVideo = (response: any): VideoModel | null => {
    if (response) {
        return {
            id: response?.id ? response.id : '',
            entityType: response?.entity_type ? response.entity_type : '',
            title: response?.title ? response.title : '',
            subtitle: response?.subtitle ? response.subtitle : null,
            strapline: response?.strapline ? response.strapline : null,
            footer: response?.footer ? response.footer : null,
            body: response?.body ? response.body : null,
            runAds: response?.runAds ? response.runAds : null,
            publishedAt: response?.publishedAt ? response.publishedAt : null,
            publishedUntil: response?.publishedUntil ? response.publishedUntil : null,
            status: response?.status ? response.status : '',
            type: response?.type ? response.type : null,
            origin: normalizeOrigin(response?.origin ?? null),
            important: response?.important ? response.important : null,
            customAuthor: response?.custom_author ? response.custom_author : '',
            urls: normalizeVideoUrls(response?.urls ?? null),
            adultContent: response?.adult_content ? response.adult_content : false,
            sensitiveContent: response?.sensitive_content ? response.sensitive_content : false,
            createdAt: response?.created_at ? response.created_at : '',
            updatedAt: response?.updated_at ? response.updated_at : '',
            seo: normalizeSeo(response?.seo ?? null),
            category: normalizeCategory(response?.category ?? null),
            additionalCategories: normalizeCategories(response?.additional_categories ?? null),
            authors: normalizeAuthors(response?.authors ?? null),
            mainMedia: normalizeMainMedia(response?.main_media ?? null),
            live: response?.live ? response.live : null,
        };
    }

    return null;
};

export const normalizeVideos = (response: any[]): VideoModel[] => {
    //Guarantee that there won't be any null objects in the array
    const videos = response
        .map((response) => normalizeVideo(response))
        .filter((video: VideoModel | null) => video !== null);
    return videos as VideoModel[];
};

const normalizeVideoUrls = (response: any): VideoUrlsModel | null => {
    if (response) {
        return {
            liveUrl: response?.live_url ? response.live_url : null,
            videoFiles: normalizeVideoUrlsVideoFiles(response?.video_files ?? []),
            externalUrl: response?.external_url ? response.external_url : null,
            canonicalUrl: response?.canonical_url ? response.canonical_url : null,
            publicUrlAmp: response?.public_url_amp ? response.public_url_amp : null,
            publicUrlMobile: response?.public_url_mobile ? response.public_url_mobile : null,
            publicUrlDesktop: response?.public_url_desktop ? response.public_url_desktop : null,
        };
    }

    return null;
};

const normalizeVideoUrlsVideoFiles = (response: any[]): VideoFile[] => {
    if (response.length > 0) {
        response = moveFullHDVideoToSecondIndex(response);
        return response.map((data) => {
            return {
                type: data.type,
                url: data.url,
            };
        });
    }

    return [];
};

// If there is a video file with an item type: FullHD move it to second place so that the first video won't be the FullHD
// video. The reason for this is that iPhones can't play the full hd video and display black video screens
const moveFullHDVideoToSecondIndex = (response: any) => {
    const FULL_HD = 'fullhd';
    const fullHdVideoFileIndex = response?.findIndex((data: any) => data.type.toLowerCase() === FULL_HD);

    if (fullHdVideoFileIndex > 0) {
        const fullHdVideo = response[fullHdVideoFileIndex];
        response.splice(fullHdVideoFileIndex, 1);
        response.splice(1, 0, fullHdVideo);
    }

    return response;
};
