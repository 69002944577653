import { OriginModel } from '../model/OriginModel';

export const normalizeOrigin = (response: any): OriginModel | null => {
    if (response) {
        return {
            id: response?.id ? response.id : null,
            slug: response?.slug ? response.slug : null,
            name: response?.name ? response.name : null,
            default: response?.default ? response.default : null,
            description: response?.description ? response.description : null,
            createdAt: response?.created_at ? response.created_at : null,
            updatedAt: response?.updated_at ? response.updated_at : null,
        };
    }

    return null;
};
