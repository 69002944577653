import { ImageModel } from '../../image/models/ImageModel';
import { VideoModel } from '../../video/models/VideoModel';
import { normalizeImage } from '../../image/normalizers/ImageNormalizer';
import { normalizeVideo } from '../../video/normalizers/VideoNormalizer';

export enum ResourceTypes {
    EMBED = 'embed',
    VIDEO = 'video',
    IMAGE = 'image',
    NONE = 'none',
}

export const normalizeMainMedia = (response: any[]): (string | ImageModel | VideoModel | null)[] => {
    if (response) {
        return response.map((data) => normalizeMedia(data));
    }

    return [];
};

const normalizeMedia = (media: any): string | ImageModel | VideoModel | null => {
    switch (media?.resource_type) {
        case ResourceTypes.IMAGE: {
            // NOTE: Workaround for main media in categories, the main media in categories, has an empty data field (null)
            media.data = media.data ? media.data : { id: media.resource_id, entity_type: media.resource_type };

            return normalizeImage(media.data);
        }
        case ResourceTypes.VIDEO: {
            return normalizeVideo(media.data);
        }
        case ResourceTypes.EMBED: {
            return media.data.embedCode;
        }
        default: {
            return '';
        }
    }
};
