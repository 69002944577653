import { OriginModel } from '../../origin/model/OriginModel';
import { AuthorModel } from '../../author/models/AuthorModel';

export interface ImageModel {
    id: string;
    entityType: string | null;
    description: string | null;
    path: string | null;
    sensitiveContent: null;
    createdAt: string | null;
    updatedAt: string | null;
    alt: string | null;
    caption: string | null;
    authors: AuthorModel[];
    urls: ImageUrlsModel | null;
    origin: OriginModel | null;
}

export interface ImageUrlsModel {
    ratio1x1: ImageRatioModel | null;
    ratio2x3: ImageRatioModel | null;
    ratio3x2: ImageRatioModel | null;
    ratio3x4: ImageRatioModel | null;
    ratio4x3: ImageRatioModel | null;
    ratio16x9: ImageRatioModel | null;
    ratio9x16: ImageRatioModel | null;
    uploaded: ImageUploadedModel | null;
}

export interface ImageRatioModel {
    top: string | null;
    left: string | null;
    right: string | null;
    bottom: string | null;
}

export interface ImageUploadedModel {
    embed: string;
    gallery: string;
    original: string;
    thumbnail: string;
}

export enum ImageRatios {
    RATIO_1X1 = '1x1',
    RATIO_2X3 = '2x3',
    RATIO_3X2 = '3x2',
    RATIO_3X4 = '3x4',
    RATIO_4X3 = '4x3',
    RATIO_16X9 = '16x9',
    RATIO_9X16 = '9x16',
    NONE = 'none',
}
export enum ImageSizes {
    BIG = 'BIG',
    MID = 'MID',
    SMALL = 'SMALL',
}
export const IMAGE_RATIO_RESOLUTIONS = {
    [`${ImageSizes.BIG}_${ImageRatios.RATIO_4X3}`]: {
        width: '3072',
        height: '2304',
    },
    [`${ImageSizes.MID}_${ImageRatios.RATIO_4X3}`]: {
        width: '1536',
        height: '1152',
    },
    [`${ImageSizes.SMALL}_${ImageRatios.RATIO_4X3}`]: {
        width: '960',
        height: '720',
    },
    [`${ImageSizes.BIG}_${ImageRatios.RATIO_16X9}`]: {
        width: '3072',
        height: '1728',
    },
    [`${ImageSizes.MID}_${ImageRatios.RATIO_16X9}`]: {
        width: '2048',
        height: '1152',
    },
    [`${ImageSizes.SMALL}_${ImageRatios.RATIO_16X9}`]: {
        width: '1280',
        height: '720',
    },
};

export const DEFAULT_IMAGE_RATIO = ImageRatios.RATIO_4X3;
export const DEFAULT_IMAGE_SIZE = ImageSizes.MID;
