import { CategoryModel } from '../models/CategoryModel';
import { normalizeSeo } from '../../seo/normalizers/SeoNormalizer';
import { normalizeMainMedia } from '../../mainMedia/normalizers/MainMediaNormalizer';

export const normalizeCategory = (response: any): CategoryModel | null => {
    if (response) {
        return {
            id: response.id ? response.id : null,
            entityType: response.entity_type ? response.entity_type : null,
            title: response.title ? response.title : null,
            description: response.description ? response.description : null,
            active: response.active ? response.active : null,
            parentId: response.parent_id ? response.parent_id : null,
            weight: response.weight ? response.weight : null,
            mainMedia: normalizeMainMedia(response.main_media),
            seo: normalizeSeo(response.seo),
            createdAt: response.created_at ? response.created_at : null,
            updatedAt: response.created_at ? response.updated_at : null,
        };
    }

    return null;
};

export const normalizeCategories = (response: any[]): CategoryModel[] => {
    if (response) {
        return response.map((data: any) => {
            if (data) {
                return normalizeCategory(data);
            }
            return data;
        });
    }

    return [];
};
