import { fetchData } from '../../../http/Http';

export const fetchAllVideos = async (limit: string = '20', page: string = '1') => {
    return await fetchData(`/videos/search?query=*&status=active&limit=${limit}&page=${page}`, 'GET');
};

export const fetchVideoById = async (videoId: string) => {
    const response = await fetchData(`/videos/${videoId}`, 'GET');
    return response.data;
};
