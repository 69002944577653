import fetch from 'isomorphic-fetch';
import * as fs from 'fs';
import { access } from 'fs';

let inMemoryTokenResp: TokenResponse | null = null;

export const fetchData = async (endpoint: string, method: string): Promise<any> => {
    const baseUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${endpoint}`;

    if (inMemoryTokenResp === null || inMemoryTokenResp.expires_at < new Date().getTime()) {
        try {
            inMemoryTokenResp = await getToken();
        } catch (e) {
            console.error('Root fetch error:', e);
            return null;
        }
    }

    if (inMemoryTokenResp && inMemoryTokenResp.access_token) {
        const response = await fetch(`${baseUrl}`, {
            method: method,
            headers: {
                Authorization: `Bearer ${inMemoryTokenResp?.access_token}`,
                Project: `${process.env.NEXT_PUBLIC_PROJECT_DOMAIN}`,
            },
        });

        return await response.json();
        // const contentType = response.headers.get('content-type');
        // if (contentType && contentType.includes('application/json')) {
        //     return await response.json();
        // }
        //
        // return await response.text();
    }

    return null;
};

// Function to retrieve the auth token
// Checks if there is a file with the token locally, if not, makes a request and creates the file with the response and returns the token.
// If there is a file checks if the token has expired, if it is, makes a request and updates it and returns the token.
async function getToken(): Promise<TokenResponse | null> {
    const tokenResp = await getTokenFromFile();

    if (tokenResp && tokenResp.access_token && tokenResp.expires_at >= new Date().getTime()) {
        return tokenResp;
    } else {
        try {
            const resp = await authenticate();
            if (resp) {
                await createTokenFile(resp);
                return resp;
            }
            return null;
        } catch (e) {
            console.error('Error storing token: ', e);
            return null;
        }
    }
}

async function authenticate(): Promise<TokenResponse | null> {
    const username = `${process.env.NEXT_PUBLIC_USERNAME}`;
    const password = `${process.env.NEXT_PUBLIC_PASSWORD}`;
    const clientId = `${process.env.NEXT_PUBLIC_CLIENT_ID}`;
    const clientSecret = `${process.env.NEXT_PUBLIC_CLIENT_SECRET}`;

    try {
        const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/oauth/token`, {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password,
                client_secret: clientSecret,
                client_id: clientId,
                scope: '',
                grant_type: 'password',
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new AuthenticationError('Authentication failed');
        }

        const data = await response.json();

        return {
            access_token: data.access_token,
            expires_in: data.expires_in,
            expires_at: new Date().getTime() + data.expires_in,
        };
    } catch (error) {
        console.error(error);
        return null;
    }
}

async function getTokenFromFile(): Promise<TokenResponse | null> {
    const filePath = process.env.NEXT_PUBLIC_TOKEN_FILE_PATH ?? '';
    return new Promise((resolve, reject) => {
        access(filePath, fs.constants.F_OK, (e) => {
            if (e === null) {
                fs.readFile(filePath, 'utf8', (err, data) => {
                    try {
                        const jsonData = JSON.parse(data);
                        resolve(jsonData);
                    } catch (parseError) {
                        reject(parseError);
                        console.error('Error parsing JSON:', parseError);
                    }
                });
            } else {
                resolve(null);
            }
        });
    });
}

async function createTokenFile(data: TokenResponse | null): Promise<TokenResponse | null> {
    const filePath = process.env.NEXT_PUBLIC_TOKEN_FILE_PATH ?? '';
    return new Promise((resolve, reject) => {
        const dataToWrite = JSON.stringify(data);
        fs.writeFile(filePath, dataToWrite, 'utf8', (err) => {
            if (err === null) {
                resolve(data);
            } else {
                reject(err);
            }
        });
    });
}

class AuthenticationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'AuthenticationError';
    }
}

export interface TokenResponse {
    access_token: string;
    expires_in: number;
    expires_at: number;
}
