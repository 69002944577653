import { AuthorModel } from '../models/AuthorModel';
import { normalizeSeo } from '../../seo/normalizers/SeoNormalizer';

export const normalizeAuthor = (response: any): AuthorModel | null => {
    if (response) {
        return {
            id: response?.id ? response.id : null,
            entityType: response?.entity_type ? response.entity_type : null,
            name: response?.name ? response.name : null,
            bio: response?.bio ? response.bio : null,
            active: response?.active ? response.active : false,
            default: response?.default ? response.default : null,
            avatarUrl: response?.avatar_url ? response.avatar_url : null,
            createdAt: response?.created_at ? response.created_at : null,
            updatedAt: response?.updated_at ? response.updated_at : null,
            weight: response?.weight ? response.weight : null,
            seo: normalizeSeo(response?.seo),
        };
    }

    return null;
};

export const normalizeAuthors = (response: any[]): AuthorModel[] => {
    if (response) {
        return response.map((data) => {
            if (data) {
                return normalizeAuthor(data);
            }
            return data;
        });
    }

    return [];
};
