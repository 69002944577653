import { SeoModel } from '../models/SeoModel';

export const normalizeSeo = (response: any | null | undefined): SeoModel => {
    return {
        title: response?.title ? response?.title : null,
        slug: response?.slug ? response?.slug : null,
        keywords: response?.keywords ? response?.keywords : null,
        index: response?.index ? response?.index : null,
        follow: response?.follow ? response?.follow : null,
        jsonld: response?.jsonld ? response?.jsonld : null,
        description: response?.description ? response?.description : null,
    };
};
