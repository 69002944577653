import { ImageModel } from '../image/models/ImageModel';
import { VideoModel } from '../video/models/VideoModel';
import { ResourceTypes } from './normalizers/MainMediaNormalizer';
import { LeadingMedia } from './models/LeadingMedia';
import { extractVideoFiles } from '../video/Video';
import * as process from 'process';

export const getMainImageFromMainMedia = (
    mainMedia: (string | ImageModel | VideoModel | null)[],
): ImageModel | null => {
    if (mainMedia) {
        return mainMedia[0] as ImageModel;
    }

    return null;
};

export const getMainMediaImageOriginalUrl = (mainMedia: (string | ImageModel | VideoModel | null)[]): string => {
    const image = getMainImageFromMainMedia(mainMedia);
    const IMAGE_CDN_URL = process.env.IMAGE_CDN_URL;

    if (image) {
        return `${IMAGE_CDN_URL}/process/${image.path}`;
    }

    return '';
};

export const getLeadingMediaOfContent = (mainMedia: (string | ImageModel | VideoModel | null)[]): LeadingMedia => {
    const rawVideo = mainMedia.find(
        (media) => media && typeof media !== 'string' && media.entityType === ResourceTypes.VIDEO,
    );
    const embed = mainMedia.find((media) => media && typeof media === 'string');
    const image = mainMedia.find(
        (media) => media && typeof media !== 'string' && media.entityType === ResourceTypes.IMAGE,
    );

    if (rawVideo) {
        return {
            resourceType: ResourceTypes.VIDEO,
            data: extractVideoFiles(rawVideo as VideoModel),
        };
    }

    if (embed) {
        return {
            resourceType: ResourceTypes.EMBED,
            data: embed as string,
        };
    }

    if (image) {
        return {
            resourceType: ResourceTypes.IMAGE,
            data: image as ImageModel,
        };
    }

    return {
        resourceType: ResourceTypes.NONE,
        data: null,
    };
};
